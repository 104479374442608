<template>
  <div class="id-check">
    <van-loading type="spinner" color="#1989fa" />
  </div>
</template>
<script>
import { detectresult } from '@/api/candidate'
export default {
  data() {
    return {
      loading: true,
    }
  },

  computed: {},
  mounted() {
    this.checkId()
  },
  methods: {
    checkId() {
      this.loading = true
      if (+this.$route.query?.code === 0) {
        console.log('验证通过')
        const { redirectUrl, interviewId, jobseekerChannelId, idNo, code, orderNo, h5faceId, newSign } = this.$route.query
        const url = decodeURIComponent(redirectUrl)
        const data = {
          interviewId,
          jobseekerChannelId,
          idNo,
          code,
          orderNo,
          h5faceId,
          newSign,
        }
        detectresult(data)
          .then(res => {
            if (res && res.code === 0) {
              // this.loading = false
              console.log(url)

              if (url.includes('tencent')) {
                this.$router.push({
                  path: '/mobilemonitor',
                  query: { jobseekerChannelId, redirectUrl },
                })
              } else {
                location.href = url
              }
              console.log(url)
            }
          })
          .catch(() => {
            this.$router.push({
              path: '/idcollect',
              query: { ...this.$route.query },
            })
          })
      } else {
        this.$router.push({
          path: '/idcollect',
          query: { ...this.$route.query },
        })
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.id-check {
  // max-width: 500px;
  // margin: auto;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
